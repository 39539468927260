/* You can add global styles to this file, and also import other style files */

a {
    cursor: pointer;
}
.ngx-pagination .current {
    background: #8cc63f !important;
}
/* @import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default.scss'; */

/* @import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default.scss'; */